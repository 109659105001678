import type { DropdownMenu } from "@kobalte/core";
import { stAnalytics } from "@repo/analytics";
import { TbClipboardCopy, TbHelpCircle, TbMoodHappy, TbPencil, TbSearch, TbShare3, TbUsers } from "solid-icons/tb";
import { type Component, Show, createEffect, createSignal } from "solid-js";
import { StDropdown, type StDropdownMenuItem } from "~/components/_original/StDropdown";
import { useThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import type { AuthenticatedIdentity } from "~/domains/identity/types";
import { MyersBriggsShortToLong, MyersBriggsTypes, responsePromptsData } from "~/lib/data";
import { urls } from "~/lib/urls";
import { ShareThreadModal } from "~/screens/modals/ShareThreadModal";
import { useUIState } from "~/ui/UIState";
import { useWire } from "~/wire";

export const TextSelectionMenu: Component<{
  rect: DOMRect | undefined;
  from: "select" | "block";
  getText: () => string;
  getEl: () => HTMLElement | undefined;
  opts?: DropdownMenu.DropdownMenuRootProps;
  content?: DropdownMenu.DropdownMenuContentProps;
  mount?: HTMLElement;
  animatedMovement?: boolean;
  onClose?: () => void;
  isThreadReadOnly: boolean;
}> = (props) => {
  const { submitPrompt, typePrompt, editor } = usePromptContext();
  const wire = useWire();
  const [portalRef, setRef] = createSignal<HTMLDivElement>();

  const { threadEventProps } = useThreadEventProperties();

  const emitTracking = (action: string, extraProps?: Record<string, string>) => {
    stAnalytics.track("text_selection_menu_action", {
      action,
      from: props.from,
      ...threadEventProps(),
      ...extraProps,
    });
  };

  // Workaround for the kobalte dropdown menu not letting us pass a class for div in charge
  // of positioning the menu on screen
  createEffect(() => {
    if (!props.animatedMovement) return;
    const p = portalRef();
    const child = p?.childNodes[0];
    if (child instanceof HTMLElement) {
      // Using a timeout because the initial positioning of the modal is at 0,0 on the screen
      // if we add a transition class imediately it'll show it zooming around the screen to get
      // to the initial position
      setTimeout(() => {
        child.classList.add("transition-transform");
      }, 300);
    }
  });

  const onItemSelected = () => {
    getSelection()?.removeAllRanges();
    props.onClose?.();
  };

  //#region menu-options
  const understand = (): StDropdownMenuItem => ({
    kind: "menu",
    trigger: {
      content: "Help me understand this",
      icon: TbHelpCircle,
    },
    items: [
      {
        kind: "item",
        content: "Explain this to me like I'm five",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.explainLikeImFive(text),
              mentionedAssets: [],
            });
            emitTracking("explain_like_im_child");
            onItemSelected();
          },
        },
      },
      {
        kind: "item",
        content: "Why does this matter?",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.whyDoesThisMatter(text),
              mentionedAssets: [],
            });
            emitTracking("why_does_this_matter");
            onItemSelected();
          },
        },
      },
    ],
  });

  const goDeeper = (): StDropdownMenuItem => ({
    kind: "menu",
    trigger: {
      content: "Get a more in-depth answer",
      icon: TbSearch,
    },
    items: [
      {
        kind: "item",
        content: "Get more detail on this",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.getMoreDetail(text),
              mentionedAssets: [],
            });
            emitTracking("get_more_detail");
            onItemSelected();
          },
        },
      },
      {
        kind: "item",
        content: "Find similar content like this",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.findMoreLikeThis(text),
              mentionedAssets: [],
            });
            emitTracking("find_similar_content");
            onItemSelected();
          },
        },
      },
    ],
  });

  const rewrite = (): StDropdownMenuItem => ({
    kind: "menu",
    trigger: {
      content: "Rewrite this for a different audience",
      icon: TbPencil,
    },
    items: [
      {
        kind: "item",
        content: "For brevity",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.rewriteForBrevity(text),
              mentionedAssets: [],
            });
            emitTracking("rewrite_for_brevity");
            onItemSelected();
          },
        },
      },
      {
        kind: "item",
        content: "For an executive audience",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.rewriteForExecutive(text),
              mentionedAssets: [],
            });
            emitTracking("rewrite_for_executive");
            onItemSelected();
          },
        },
      },
      {
        kind: "item",
        content: "For a less technical audience",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.rewriteForLessTechnical(text),
              mentionedAssets: [],
            });
            emitTracking("rewrite_for_less_technical_audience");
            onItemSelected();
          },
        },
      },
      {
        kind: "item",
        content: "For a more technical audience",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.rewriteForMoreTechnical(text),
              mentionedAssets: [],
            });
            emitTracking("rewrite_for_more_technical_audience");
            onItemSelected();
          },
        },
      },
      {
        kind: "menu",
        trigger: {
          content: "Rewrite so this personality type can best receive it",
          icon: TbUsers,
        },
        items: [
          {
            kind: "group",
            label: "Myers Briggs Personality Types:",
            items: MyersBriggsTypes.map((m) => ({
              kind: "item",
              content: (
                <span>
                  <span class="font-bold">{m}</span>-{MyersBriggsShortToLong(m)}
                </span>
              ),
              props: {
                class: "px-1",
                onSelect: () => {
                  const text = props.getText();
                  submitPrompt({
                    text: responsePromptsData.rewriteForMyersBriggs(text, m),
                    mentionedAssets: [],
                  });
                  emitTracking("rewrite_for_personality_type", {
                    personalityType: m,
                  });
                  onItemSelected();
                },
              },
            })),
          },
        ],
      },
    ],
  });

  const analyzeFeelings = (): StDropdownMenuItem => ({
    kind: "menu",
    trigger: {
      content: "Analyze feelings and needs",
      icon: TbMoodHappy,
    },
    items: [
      {
        kind: "item",
        content: "What feelings and emotions were expressed?",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.analyzeFeelingsEmotions(text),
              mentionedAssets: [],
            });
            emitTracking("analyze_feelings_emotions");
            onItemSelected();
          },
        },
      },
      {
        kind: "item",
        content: "What met or unmet needs were shared?",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.analyzeMetUnmetNeeds(text),
              mentionedAssets: [],
            });
            emitTracking("analyze_met_unmet_needs");
            onItemSelected();
          },
        },
      },
      {
        kind: "item",
        content: "How could this have gone better?",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.analyzeGoneBetter(text),
              mentionedAssets: [],
            });
            emitTracking("analyze_gone_better");
            onItemSelected();
          },
        },
      },
      {
        kind: "item",
        content: "What's really happening inside this person right now?",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.analyzePersonInner(text),
              mentionedAssets: [],
            });
            emitTracking("analyze_person_inner");
            onItemSelected();
          },
        },
      },
      {
        kind: "item",
        content: "What's happening under the surface here?",
        props: {
          class: "px-2",
          onSelect: () => {
            const text = props.getText();
            submitPrompt({
              text: responsePromptsData.analyzeUnderSurface(text),
              mentionedAssets: [],
            });
            emitTracking("analyze_under_surface");
            onItemSelected();
          },
        },
      },
    ],
  });

  const askFollowUp = (): StDropdownMenuItem => ({
    kind: "item",
    content: "Ask a follow-up question about this",
    props: {
      class: "px-2",
      onSelect: async () => {
        const text = responsePromptsData.askFollowUp(props.getText());

        const content = {
          type: "doc",
          content: text.split("\n").map((paragraph) => ({
            type: "paragraph",
            content:
              paragraph.trim() === ""
                ? []
                : [
                    {
                      type: "text",
                      text: paragraph,
                    },
                  ],
          })),
        };
        await typePrompt(content, { instant: true });

        setTimeout(() => {
          editor()?.commands.focus();
          // Get the end of the first line and move the cursor there
          const selection = (text.split("\n")[0]?.length || 0) + 1;
          editor()?.commands.setTextSelection({ from: selection, to: selection });
        }, 300);

        emitTracking("ask_follow_up");
        onItemSelected();
      },
    },
  });

  const state = useUIState();
  const [modalOpen, setModalOpen] = state.modal;
  const [modalContents, setModalContents] = state.modalContents;

  const share = (): StDropdownMenuItem => ({
    kind: "item",
    content: "Share",
    icon: TbShare3,
    props: {
      class: "px-2",
      onSelect: () => {
        const id = props.getEl()?.getAttribute("data-block") ?? "";
        const threadId = wire.services.threads.snapshot.context.threadId ?? "";
        // TODO - @andi removed the encoding for deep linking
        // navigator.clipboard.writeText(window.location.origin + urls.threadV2(threadId ?? ""));
        setModalOpen("share-thread-modal");
        setModalContents(() => () => (
          <ShareThreadModal
            type="link"
            onClose={() => {
              setModalOpen("");
              setModalContents(null);
            }}
            threadId={threadId}
            // messageId={props.message.messageId}
          />
        ));
        emitTracking("share_via_link");
        onItemSelected();
      },
    },
  });
  // const share = (): StDropdownMenuItem => ({
  //   kind: "menu",
  //   trigger: {
  //     content: "Share",
  //     icon: TbShare3,
  //   },
  //   items: [
  //     {
  //       kind: "item",
  //       content: "Link to this response",
  //       props: {
  //         class: "px-2",
  //         onSelect: () => {
  //           const id = props.getEl()?.getAttribute("data-block") ?? "";
  //           const threadId = wire.services.threads.snapshot.context.threadId ?? "";
  //           // TODO - @andi removed the encoding for deep linking
  //           // navigator.clipboard.writeText(window.location.origin + urls.threadV2(threadId ?? ""));
  //           setModalOpen("share-thread-modal");
  //           setModalContents(() => () => (
  //             <ShareThreadModal
  //               type="link"
  //               onClose={() => {
  //                 setModalOpen("");
  //                 setModalContents(null);
  //               }}
  //               threadId={threadId}
  //               // messageId={props.message.messageId}
  //             />
  //           ));
  //           emitTracking("share_via_link");
  //           onItemSelected();
  //         },
  //       },
  //     },
  //     {
  //       kind: "item",
  //       content: "Share via email",
  //       props: {
  //         class: "px-2",
  //         onSelect: () => {
  //           const text = props.getText();

  //           open(
  //             responsePromptsData.shareViaEmailMailtoLink({
  //               text,
  //               threadName: wire.services.threads.snapshot.context.label || "a thread",
  //               threadUrl: `https://storytell.ai${urls.threadV2(
  //                 wire.services.threads.snapshot.context.threadId || "",
  //               )}`,
  //               userDisplayName: (wire.services.identity.snapshot.context.identity as AuthenticatedIdentity)
  //                 .displayName,
  //             }),
  //           );
  //           emitTracking("share_via_email");
  //           onItemSelected();
  //         },
  //       },
  //     },
  //   ],
  // });

  const copy = (): StDropdownMenuItem => ({
    kind: "item",
    content: "Copy text",
    icon: TbClipboardCopy,
    props: {
      class: "pl-1",
      onSelect: () => {
        const text = props.getText();
        navigator.clipboard.writeText(text);
        emitTracking("copy_text");
        onItemSelected();
      },
    },
  });
  //#endregion

  return (
    <Show when={props.rect}>
      <StDropdown
        theme="invert"
        opts={{
          modal: false,
          overlap: true,
          preventScroll: true,
          placement: "bottom-start",
          open: !!props.rect,
          ...props.opts,
        }}
        setRef={setRef}
        mount={props.mount}
        content={props.content}
        items={
          !props.isThreadReadOnly
            ? [
                understand(),
                goDeeper(),
                rewrite(),
                analyzeFeelings(),
                askFollowUp(),
                { kind: "separator" },
                share(),
                copy(),
              ]
            : [copy()]
        }
        trigger={{
          as: "div",
          class: "pointer-events-none fixed",
          style: {
            top: `${props.rect?.top || 0}px`,
            left: `${props.rect?.left || 0}px`,
            width: `${props.rect?.width || 0}px`,
            height: `${props.rect?.height || 0}px`,
          },
          "aria-hidden": "true",
        }}
      />
    </Show>
  );
};
