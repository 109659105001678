import { type ParentComponent, createSignal, Show } from "solid-js";
import { ThreadTag } from "./ThreadTag";
import type { IconName } from "~/components/icons";
import { useUIState } from "~/ui/UIState";

export const ThreadTimelineUnit: ParentComponent<{
  tagLabel: string;
  menuItems?: { label: string; onClick: () => void; icon: IconName }[];
  muted?: boolean;
  section: "answer" | "reasoning" | "gotchas" | "consider" | "rest";
}> = (props) => {
  const {
    threadResponseSectionMinimized: [minimized, setMinimized],
  } = useUIState();
  const [mini, setMini] = createSignal(minimized[props.section]);

  return (
    <div class="relative">
      <div
        data-minimized={mini()}
        data-muted={props.muted}
        class="absolute top-5 -left-12 -bottom-4 w-0.5 bg-primary data-[muted=true]:bg-background-decoration data-[minimized=true]:hidden"
      />
      <ThreadTag
        muted={props.muted}
        isMinimized={mini()}
        toggleMinimize={() => {
          const newMini = !mini();
          setMini(newMini);
          setMinimized(props.section, newMini);
        }}
        menuItems={props.menuItems}
      >
        {props.tagLabel}
      </ThreadTag>
      <div data-minimized={mini()} class="data-[minimized=true]:hidden">
        {props.children}
      </div>
    </div>
  );
};
