import { stAnalytics } from "@repo/analytics";
import { Show, splitProps } from "solid-js";
import { getScreenType } from "~/lib/ui/getScreenType";
import { CollectionAssetsDrawerScreen } from "~/screens/drawer/CollectionAssetsDrawerScreen";
import { useUIState } from "~/ui/UIState";
import styles from "./PromptCollectionCTA.module.css";
import type { CTAProps } from "./types";
import { useWire } from "~/wire";
import { getRootCollectionUIOverloads } from "~/domains/collections/collections.helpers";
import { StIcon } from "../icons/StIcon";

interface CustomAnchorProps extends CTAProps {
  "data-test-id": string;
  accessiblePrefix?: string;
  accessibleSuffix?: string;
  label: string;
  onClick?: (event: Event) => void;
  collectionId: string;
}
type Props = RequireAtLeastOne<CustomAnchorProps, "accessiblePrefix" | "accessibleSuffix">;

export const PromptCollectionCTA = (props: Props) => {
  const [local, rest] = splitProps(props, ["label", "accessiblePrefix", "accessibleSuffix", "onClick"]);
  const state = useUIState();
  const [open, setOpen] = state.rightDrawer;
  const [, setRightDrawerContents] = state.rightDrawerContents;

  const wire = useWire();
  const collection = () => wire.services.collections.getCollection(props.collectionId);
  const overrides = () => getRootCollectionUIOverloads(collection());

  const onClick = (event: Event) => {
    setRightDrawerContents(() => () => <CollectionAssetsDrawerScreen shown={open} collectionId={props.collectionId} />);
    if (!open()) {
      setOpen(true);
    }
    stAnalytics.track("click_tracking", {
      cta: "show_tag_assets_drawer",
      position: "chat_header",
      screen: getScreenType(),
    });
    if (props.onClick) props.onClick(event);
    event.stopImmediatePropagation();
    event.preventDefault();
  };

  return (
    <button {...rest} class={styles["prompt-tag-cta"]} tabIndex={0} on:click={onClick}>
      <Show when={local.accessiblePrefix}>
        <span class="screen-reader">{local.accessiblePrefix}</span>
      </Show>

      <StIcon class={styles["prompt-tag-cta__icon"]} icon={overrides()?.icon!} size={20} />

      <span class={styles["prompt-tag-cta__label"]}>{overrides()?.label || local.label}</span>
      <Show when={local.accessibleSuffix}>
        <span class="screen-reader">{local.accessibleSuffix}</span>
      </Show>
    </button>
  );
};
