import { type AnalyticsEventPayloads, stAnalytics } from "@repo/analytics";
import {
  type MessageInsertFileUpload,
  type ThreadMessage,
  ThreadMessageKinds,
  type curator,
  getRequestClient,
  isMessageInsertFileUpload,
  isMessageInsertSignUpLimit,
  isMessageKnowledgeV1,
  isMessagePrompt,
  isMessageTextV1,
} from "@repo/client";
import { createElementSize } from "@solid-primitives/resize-observer";
import { useNavigate, useSearchParams } from "@solidjs/router";
import { TbBug, TbCheck, TbLoader2, TbSettings, TbShare, TbShare2, TbShare3, TbTrash } from "solid-icons/tb";
import {
  type Accessor,
  type Component,
  For,
  Match,
  Show,
  Switch,
  createEffect,
  createSignal,
  on,
  onCleanup,
  onMount,
} from "solid-js";
import { StButton } from "~/components/_original/StButton";
import { FloatingPromptBarContainer } from "~/components/containers/FloatingPromptBarContainer";
import { StIcon } from "~/components/icons";
import { PersistentPrompt } from "~/domains/chat/prompt/PersistentPrompt";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import { useIsIdentityConnecting } from "~/domains/identity/hooks";
import { isAuthenticatedIdentity } from "~/domains/identity/types";
import type { CampaingPageDataPrompt } from "~/domains/marketing/useUseCasesData";
import { TextPromptUnitV1 } from "~/domains/threads/components/units/TextPromptUnitV1";
import { TextUnitV1 } from "~/domains/threads/components/units/TextUnitV1";
import type { ThreadGroup, ThreadUnit } from "~/domains/threads/screens/ThreadScreen";
import { newClearErrors } from "~/domains/ws/machines";
import { NotFoundScreen } from "~/screens/NotFoundScreen";
import { GLOBAL_ELEMENT_ATTRIBUTES } from "~/ui/global-element-attributes";
import { useWire } from "~/wire";
import { ThreadTimelineUnit } from "../../ThreadTimelineUnit";
import { InsertFileUploadUnit } from "../../units/InsertFileUploadUnit";
import { InsertSignUpUnit } from "../../units/InsertSignUpUnit";
import { KnowledgeChangeUnitV1 } from "../../units/KnowledgeChangeUnitV1";
import { MarkdownRenderer } from "../../units/MarkdownRenderer";
import styles from "./ThreadPanel.module.css";
import { IconCTA } from "~/components/cta/IconCTA";
import { SecondaryCTA } from "~/components/cta/SecondaryCTA";
import { urls } from "~/lib/urls";
import { ShareThreadModal } from "~/screens/modals/ShareThreadModal";
import { useUIState } from "~/ui/UIState";
import { CustomEvents } from "~/ui/custom-events";
import { captureException } from "@repo/observability";
import { DeleteThreadModal } from "~/screens/modals/DeleteThreadModal";
import { GLOBAL_ELEMENT_IDS } from "~/ui/global-element-ids";
import { Portal } from "solid-js/web";
import { ContextMenu } from "~/components/context-menu/ContextMenu";

interface Props {
  prompt?: CampaingPageDataPrompt;
  messages: (ThreadGroup | ThreadUnit)[];
  isNewThreadId: boolean;
  isThreadReadOnly: boolean;
  collectionId: string | undefined;
}

export const ThreadPanel = (props: Props) => {
  const wire = useWire();
  const [containerRef, setContainerRef] = createSignal<HTMLDivElement>();
  let threadEndRef!: HTMLDivElement;

  const state = useUIState();
  const [, setModalOpen] = state.modal;
  const [, setModalContents] = state.modalContents;
  const signal = state.collectionThreadsContextMenu;

  const navigate = useNavigate();

  const thread = () =>
    wire.services.threads.snapshot.context.threadId
      ? wire.services.threads.getThread(wire.services.threads.snapshot.context.threadId)
      : undefined;

  const client = getRequestClient(wire.services.identity.getIdentityToken);

  const tombstone = async (id: string) => {
    try {
      await client.controlplane.TombstoneThreads({
        threadIds: [id],
      });
      CustomEvents.collectionThreadsUpdated.dispatch();
    } catch (error) {
      captureException(error);
      console.error(error);
    }
  };

  const onDeleteThread = (id: string, label: string) => {
    setModalOpen("delete-thread");
    setModalContents(() => () => (
      <DeleteThreadModal
        id={"edit-asset"}
        onDelete={async (): Promise<void> => {
          await tombstone(id);
          CustomEvents.collectionThreadsUpdated.dispatch();
          setModalOpen("");
          setModalContents(null);

          navigate(urls.collection(props.collectionId || ""));
        }}
        onClose={() => {
          setModalOpen("");
          setModalContents(null);
        }}
        label={label}
      />
    ));
  };

  /**
   * Sends data to analytics when an error is encountered.
   * @see {@link https://linear.app/storytell/issue/ENG-1444/tag-errors-in-posthog-so-we-can-track-them|ENG-1444}
   */
  const hasErrors = (): boolean => {
    const errors = wire.services.websocket.snapshot.context.errorMessages;
    if (wire.services.websocket.snapshot.context.errorMessages.length > 0) {
      errors.forEach((error) => {
        const data: AnalyticsEventPayloads["error_in_thread_view"] = {
          errorCode: error.code,
          errorMessage: error.message,
          email: null,
          userId: null,
        };
        if (
          wire.services.identity?.snapshot.context.identity &&
          isAuthenticatedIdentity(wire.services.identity.snapshot.context.identity)
        ) {
          data.email = wire.services.identity.snapshot.context.identity.email || null;
          data.userId = wire.services.identity.snapshot.context.identity.userId || null;
        }
        if (data.errorCode !== "not_found") {
          stAnalytics.track("error_in_thread_view", data);
        } else {
          stAnalytics.track("thread_not_found", data);
        }
      });
      return true;
    }
    return false;
  };

  const threadTitle = () => thread()?.label || "New";

  const isConnecting = useIsIdentityConnecting();

  const handleClearErrors = () => {
    wire.services.websocket.send(newClearErrors());
  };

  const [threadLoaded, setThreadLoaded] = createSignal(false);

  const is404 = () => !isConnecting() && thread() === undefined && !props.prompt;

  const loadUnload = (load: boolean) => () => {
    setThreadLoaded(load);
    return undefined;
  };

  onCleanup(() => {
    handleClearErrors();
  });

  useThreadAutoScroll(
    containerRef,
    () => threadEndRef,
    () => props.messages,
    threadLoaded,
  );

  const isCampaignScreen = () =>
    props.prompt && props.messages.filter((m) => m.type !== "unit" || m.content.kind !== "thread-insert").length === 0;

  const [configRef, setConfigRef] = createSignal<HTMLElement>((<></>) as HTMLElement);
  return (
    <Switch>
      <Match when={isCampaignScreen() && wire.services.limiting.guest.lastInteraction()?.type !== "prompt"}>
        {loadUnload(false)()}
        <PredefinedPromptDetails prompt={props.prompt} />

        <Show when={!props.isThreadReadOnly}>
          <FloatingPromptBarContainer>
            <PersistentPrompt />
          </FloatingPromptBarContainer>
        </Show>
      </Match>
      <Match
        when={
          isConnecting() ||
          (["loading", "idle"].includes(wire.services.threads.snapshot.value) &&
            !props.isNewThreadId &&
            !isCampaignScreen())
        }
      >
        {loadUnload(false)()}
        <div class="grid place-content-center h-96 text-slate-900 dark:text-slate-200">
          <StIcon icon={TbLoader2} class="animate-spin size-10" />
        </div>
      </Match>

      <Match when={is404()}>
        <NotFoundScreen />
      </Match>

      <Match when={(props.messages?.length || 0) === 0}>
        {loadUnload(false)()}
        <EmptyThreadMessage />

        <Show when={!props.isThreadReadOnly}>
          <FloatingPromptBarContainer>
            <PersistentPrompt />
          </FloatingPromptBarContainer>
        </Show>
      </Match>

      <Match when={true}>
        {loadUnload(true)()}
        <>
          <div ref={setContainerRef} class={styles["thread-panel"]}>
            <div class={styles["thread-panel__header"]}>
              <Show when={threadTitle()} fallback={<div aria-hidden />}>
                <div class="flex items-center gap-4">
                  <h2 class={styles["thread-panel__title"]}>{threadTitle()}</h2>

                  <Show when={!props.prompt}>
                    <button
                      ref={(ref) => {
                        setConfigRef(ref);
                      }}
                      class={styles["collections-title-bar__config"]}
                      type="button"
                      aria-controls="thread-config-context-menu"
                    >
                      <TbSettings class={styles["collections-title-bar__header-icon"]} size="1.75rem" />
                      <span class="screen-reader">Configure the Collection.</span>
                    </button>

                    <Portal mount={document.getElementById(GLOBAL_ELEMENT_IDS.portal) ?? document.body}>
                      <ContextMenu
                        class="fixed"
                        id="thread-config-context-menu"
                        items={[
                          {
                            label: "Delete chat",
                            icon: TbTrash,
                            onClick: () => {
                              onDeleteThread(thread()?.threadId ?? "", thread()?.label ?? "");
                            },
                          },
                        ]}
                        signal={signal}
                        placement="bottom-start"
                        bound={configRef()}
                      />
                    </Portal>
                  </Show>
                </div>
              </Show>

              <Show when={!props.isThreadReadOnly}>
                <SecondaryCTA
                  class={styles["thread-panel__header-share"]}
                  data-test-id="share-thread-button"
                  accessiblePrefix="Click to "
                  label="Share chat"
                  icon={TbShare3}
                  onClick={() => {
                    setModalOpen("share-thread-modal");
                    setModalContents(() => () => (
                      <ShareThreadModal
                        type="link"
                        onClose={() => {
                          setModalOpen("");
                          setModalContents(null);
                        }}
                        threadId={thread()?.threadId ?? ""}
                      />
                    ));
                    // const url = window.location.origin + urls.threadV2(props.message.threadId);
                    // stAnalytics.track("thread_response_shared", {
                    //   url: url,
                    //   type: "copy_link",
                    // });
                  }}
                />
              </Show>
            </div>
            <For each={props.messages}>
              {(group) => (
                <Show
                  when={group.type === "group"}
                  fallback={
                    <Switch>
                      <Match when={isMessageKnowledgeV1(group.content)}>
                        <KnowledgeChangeUnitV1 message={group.content as curator.MessageKnowledgeV1} />
                      </Match>
                      <Match when={isMessageInsertFileUpload(group.content)}>
                        <InsertFileUploadUnit message={group.content as MessageInsertFileUpload} />
                      </Match>
                      <Match when={isMessageInsertSignUpLimit(group.content)}>
                        <InsertSignUpUnit />

                        <Show when={wire.services.limiting.guest.lastInteraction()}>
                          <div class="relative pl-6 md:pl-12 mb-16 pt-10 w-[96%] mx-auto">
                            {/* <div
                              aria-hidden
                              class="opacity-50 absolute left-0 top-[3.125rem] rounded-full bottom-0 pointer-events-none w-[2px] bg-background-decoration"
                            /> */}
                            <Show when={wire.services.limiting.guest.lastInteraction()?.type === "prompt"}>
                              <TextPromptUnitV1
                                message={{
                                  kind: ThreadMessageKinds.MessageKindPromptV2,
                                  messageId: "",
                                  prompt: (
                                    wire.services.limiting.guest.lastInteraction() as {
                                      prompt: string;
                                    }
                                  ).prompt,
                                  scope: {
                                    worldKnowledge: false,
                                    collectionIDs: [],
                                    assetIDs: [],
                                    mentions: {
                                      collections: [],
                                      assets: [],
                                    },
                                  },
                                  model: "",
                                  transformationId: "",
                                  temperature: 0,
                                  max_tokens: 0,
                                  top_p: 0,
                                  frequency_penalty: 0,
                                  presence_penalty: 0,
                                  stop: [],
                                  logit_bias: {},
                                  context_window: 0,
                                  createdBy: "",
                                }}
                              />
                            </Show>

                            <TextUnitV1
                              isThreadReadOnly={props.isThreadReadOnly}
                              disableActions
                              message={{
                                messageId: "",
                                kind: ThreadMessageKinds.MessageKindTextV1,
                                parts: [
                                  wire.services.limiting.guest.lastInteraction()?.type === "prompt"
                                    ? "Create an account to get more answers"
                                    : "Create an account to analyze your file.",
                                ],
                                provenance: {
                                  processedByModel: "Storytell",
                                  suggestedModels: [],
                                  transformationId: "",
                                  outputTokens: 0,
                                  inputTokens: 0,
                                  scoped: {
                                    worldKnowledge: false,
                                    collectionReferenceIDs: [],
                                    explicitAssets: [],
                                    collectionAssets: [],
                                    citations: [],
                                  },
                                },
                                isDone: true,
                                label: "",
                                textSuggestions: [],
                                createdBy: "",
                                tenantId: "",
                                organizationId: "",
                                threadId: "",
                              }}
                            />
                          </div>
                        </Show>
                      </Match>
                    </Switch>
                  }
                >
                  <div class="relative pl-6 md:pl-12 mb-24 w-[96%] mx-auto min-h-[15rem]">
                    {/* <div
                      aria-hidden
                      class="opacity-50 absolute left-0 top-[3.125rem] rounded-full bottom-0 pointer-events-none w-[2px] bg-background-decoration"
                    /> */}
                    <For each={group.content as ThreadMessage[]}>
                      {(message) => (
                        <Switch>
                          <Match when={isMessagePrompt(message)}>
                            <TextPromptUnitV1 message={message as curator.MessagePromptV2} />
                          </Match>
                          <Match when={isMessageTextV1(message)}>
                            <TextUnitV1
                              isThreadReadOnly={props.isThreadReadOnly}
                              message={message as curator.MessageTextV1}
                            />
                          </Match>
                        </Switch>
                      )}
                    </For>
                  </div>
                </Show>
              )}
            </For>
            <Show when={hasErrors()}>
              <div class="dark:bg-red-900 dark:text-white bg-red-300 text-black px-4 py-2">
                <For each={wire.services.websocket.snapshot.context.errorMessages}>
                  {(error) => (
                    <div class="my-2 text-base leading-normal">
                      Error: {error.message} ({error.code})
                    </div>
                  )}
                </For>
                <StButton label="Clear Errors" icon={TbCheck} onClick={handleClearErrors}>
                  Clear Errors
                </StButton>
                <StButton
                  label="Report Bug"
                  icon={TbBug}
                  onClick={() => {
                    wire.services.feedback.openFeedbackPanel();
                  }}
                >
                  Report Bug
                </StButton>
              </div>
            </Show>
          </div>

          <Show when={!props.isThreadReadOnly}>
            <FloatingPromptBarContainer modifier="left">
              <PersistentPrompt />
            </FloatingPromptBarContainer>
          </Show>

          <div ref={threadEndRef} aria-hidden />
        </>
      </Match>
    </Switch>
  );
};

const useThreadAutoScroll = (
  ref: Accessor<HTMLElement | undefined>,
  threadEndRef: Accessor<HTMLElement | undefined>,
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  messages: Accessor<any[]>,
  threadLoaded: Accessor<boolean>,
) => {
  const [params] = useSearchParams();
  const size = createElementSize(ref);

  let autoscroll = true;
  // Keep track of the length of the messages array to know if new prompts have been submitted
  let oldLength = 0;

  onMount(() => {
    const observer = new MutationObserver((mutationsList) => {
      const list = mutationsList.filter((entry) => entry.type === "attributes");
      for (const mutation of list) {
        if (mutation.attributeName === GLOBAL_ELEMENT_ATTRIBUTES.scrollDirection) {
          const direction = globalThis.document?.documentElement.getAttribute(
            GLOBAL_ELEMENT_ATTRIBUTES.scrollDirection,
          );
          if (direction === "up") {
            autoscroll = false;
          }
        }
      }
    });
    observer.observe(globalThis.document?.documentElement, {
      attributes: true,
      childList: false,
      subtree: false,
    });
  });

  const [scrollTo, setScrollTo] = createSignal("");
  createEffect(() => {
    if (typeof params.scrollTo === "string") {
      setScrollTo(params.scrollTo);
    }
  });

  let lastScrolledTo = "";
  createEffect(
    on(
      () => [threadLoaded(), size.height] as const,
      ([loaded]) => {
        if (!loaded) return;

        // If new prompts have been submitted enable autoscroll again
        const len = messages().length;
        if (len !== oldLength) {
          autoscroll = true;
          oldLength = len;
        }

        if (scrollTo() && lastScrolledTo !== scrollTo()) {
          const el = document.querySelector(`[data-block="${scrollTo()}"]`);
          if (el) {
            el.scrollIntoView({ behavior: "smooth", block: "start" });
            el.classList.add("scrollto-highlight");
            setTimeout(() => {
              el.classList.remove("scrollto-highlight");
            }, 999);
            lastScrolledTo = scrollTo() || "";
            return;
          }
        }
        if (lastScrolledTo !== "") return;

        if (autoscroll) {
          threadEndRef()?.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      },
    ),
  );
};

const PredefinedPromptDetails: Component<{
  prompt?: CampaingPageDataPrompt;
}> = (props) => {
  const { editor } = usePromptContext();
  onMount(() => {
    editor()?.commands.focus();
  });

  return (
    <div class="px-20 py-20 flex flex-col justify-center items-center text-center gap-8 max-w-thread mx-auto dark:text-white">
      <h2 class="font-bold text-4xl leading-normal">{props.prompt?.prompt.name}</h2>

      <MarkdownRenderer highlight={false} md={props.prompt?.prompt.summary || ""} prefix="" />

      <Show when={props.prompt?.prompt.seoContent}>
        <section class="text-left">
          <MarkdownRenderer highlight={false} md={props.prompt?.prompt.seoContent || ""} prefix="" />
        </section>
      </Show>
    </div>
  );
};

const EmptyThreadMessage: Component = () => {
  const { editor, setShowUploadModal } = usePromptContext();
  onMount(() => {
    editor()?.commands.focus();
  });

  return (
    <div class="px-20 py-20 flex flex-col justify-center items-center text-center gap-4 max-w-thread mx-auto dark:text-white">
      <h2 class="font-bold text-4xl leading-normal">What do you want to accomplish?</h2>
      <p class="text-lg leading-normal">
        Start a new thread by submitting a prompt or by{" "}
        <button
          type="button"
          class="underline underline-offset-2 leading-normal text-lg w-auto"
          onClick={() => {
            setShowUploadModal(true);
          }}
        >
          uploading a file
        </button>
      </p>
    </div>
  );
};
