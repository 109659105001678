import { stAnalytics } from "@repo/analytics";
import type { assets as assetsNS } from "@repo/client";
import { FaSolidFolderTree } from "solid-icons/fa";
import { RiSystemUploadCloud2Line } from "solid-icons/ri";
import { TbFolderFilled } from "solid-icons/tb";
import {
  type Accessor,
  createEffect,
  createSignal,
  onCleanup,
  onMount,
  Show,
  startTransition,
  Suspense,
} from "solid-js";
import { CollectionAssetsTable } from "~/components/CollectionAssetTable";
import { Anchor } from "~/components/cta/Anchor";
import { PrimaryCTA } from "~/components/cta/PrimaryCTA";
import { SecondaryCTA } from "~/components/cta/SecondaryCTA";
import { HorizontalRule } from "~/components/HorizontalRule";
import { InfoPanel } from "~/components/InfoPanel";
import { Loading } from "~/components/Loading";
import { SectionHeader } from "~/components/SectionHeader";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import { pluralize } from "~/lib/string/pluralize";
import { formatCollectionName } from "~/lib/ui/formatCollectionName";
import { getScreenType } from "~/lib/ui/getScreenType";
import { ProcessingReadableState, readableProcessingState } from "~/lib/ui/readableProcessingState";
import { CustomEvents } from "~/ui/custom-events";
import { useWire } from "~/wire";
import styles from "./AssetsDrawerScreen.module.css";

interface Props {
  collectionId: string;
  shown: Accessor<boolean>;
}

export const CollectionAssetsDrawerScreen = (props: Props) => {
  const wire = useWire();
  const { activeCollection } = usePromptContext();
  const collection = () => wire.services.collections.getCollection(props.collectionId);
  const [assets, { refetch }] = wire.services.collections.resourceAllCollectionAssets(() => props.collectionId);
  const { setShowUploadModal } = usePromptContext();
  const [subAssets, setSubAssets] = createSignal<assetsNS.AssetSnapshot[]>([]);

  let canPoll = false;
  let timeout: ReturnType<typeof setTimeout> | undefined;
  const poll = () => {
    const current = assets()?.assets;
    if (current) {
      const result = Object.keys(current).filter((entry) => {
        if (!current[entry]) return false;
        return (
          current[entry].filter(
            (asset) =>
              readableProcessingState(asset.lifecycleState, asset.modifiedAt) !== ProcessingReadableState.Ready &&
              readableProcessingState(asset.lifecycleState, asset.modifiedAt) !== ProcessingReadableState.Failed,
          ).length > 0
        );
      });
      if (result.length > 0) {
        startTransition(() => refetch());
        if (canPoll) {
          timeout = globalThis.setTimeout(poll, 3000);
        }
      } else {
        timeout = undefined;
      }
    }
  };

  onMount(() => {
    const handler = (event: CustomEvent<UploadCompletedEvent>) => {
      console.log(event.detail);
      if (event.detail.collectionId === props.collectionId) {
        startTransition(() => refetch());
        timeout = globalThis.setTimeout(poll, 3000);
      }
    };
    CustomEvents.uploadCompleted.add(handler);
    onCleanup(() => {
      CustomEvents.uploadCompleted.remove(handler);
    });
  });

  createEffect((prev) => {
    if (prev !== props.shown() && !timeout && props.shown()) {
      poll();
    }
    if (!props.shown() && timeout) {
      clearTimeout(timeout);
    }
  });

  createEffect(() => {
    if (props.shown()) {
      const current = assets()?.assets;
      let result: assetsNS.AssetSnapshot[] = [];
      if (current && props.collectionId) {
        Object.keys(current)
          .filter((id) => id !== props.collectionId)
          .forEach((entry) => {
            if (current[entry]) {
              result = [...result, ...current[entry]];
            }
          });
        setSubAssets(result);
      }
    }
  });

  onMount(() => {
    canPoll = true;
    onCleanup(() => {
      if (timeout) {
        clearTimeout(timeout);
      }
      canPoll = false;
    });
  });

  return (
    <Suspense fallback={<Loading />}>
      <div class={styles["assets-drawer"]}>
        <p class={styles["assets-drawer__title"]}>{`Assets in your ${formatCollectionName(collection()!.label)}:`}</p>
        <Show when={collection()}>
          <div class={styles["assets-drawer__section"]}>
            <Show
              when={(assets()?.assets[collection()!.id] ?? []).length > 0}
              fallback={
                <InfoPanel
                  icon={TbFolderFilled}
                  title={`0 assets from your ${formatCollectionName(collection()!.label)}.`}
                  messages={["Assets from sub-Collections are still available."]}
                  variant="info"
                >
                  <PrimaryCTA
                    class={styles["assets-drawer__cta"]}
                    data-test-id="right-drawer-upload"
                    icon={RiSystemUploadCloud2Line}
                    label={`Add assets to your ${formatCollectionName(collection()!.label)}.`}
                    accessibleSuffix="to your current Collection."
                    onClick={() => {
                      stAnalytics.track("click_tracking", {
                        cta: "upload",
                        position: "right_drawer",
                        screen: getScreenType(),
                      });
                      setShowUploadModal(true);
                    }}
                  />
                </InfoPanel>
              }
            >
              <SectionHeader
                modifier="thin-bottom-padding"
                title={`${assets()?.assets[collection()!.id]?.length} ${pluralize(assets()?.assets[collection()!.id]?.length, "asset")} from your ${formatCollectionName(collection()!.label)}.`}
                icon={TbFolderFilled}
              />
              <CollectionAssetsTable
                modifier="drawer"
                collectionName={collection()!.label}
                data={assets()?.assets[collection()!.id]!}
              />
              <Show when={activeCollection()?.id === props.collectionId}>
                <SecondaryCTA
                  class={styles["assets-drawer__cta"]}
                  data-test-id="right-drawer-upload"
                  icon={RiSystemUploadCloud2Line}
                  label={`Add assets to your ${formatCollectionName(collection()!.label)}.`}
                  accessibleSuffix="to your current Collection."
                  onClick={() => {
                    stAnalytics.track("click_tracking", {
                      cta: "upload",
                      position: "right_drawer",
                      screen: getScreenType(),
                    });
                    setShowUploadModal(true);
                  }}
                />
              </Show>
            </Show>
          </div>
          <Show when={subAssets().length > 0}>
            <HorizontalRule />
            <div class={styles["assets-drawer__section"]}>
              <SectionHeader
                modifier="thin-bottom-padding"
                title={`${subAssets().length} additional ${pluralize(subAssets().length, "asset")} from sub-Collections.`}
                icon={FaSolidFolderTree}
              />
              <CollectionAssetsTable modifier="drawer" collectionName="Other child Collections" data={subAssets()} />
            </div>
          </Show>
        </Show>
        <p class={styles["assets-drawer__footer"]}>
          <Anchor
            data-test-id="storytile-docs-link"
            accessibleSuffix="Visit our documentation to"
            label="Learn more about assets and Collections."
            href="https://docs.storytell.ai/features/collections"
            target="_blank"
          />
        </p>
      </div>
    </Suspense>
  );
};
