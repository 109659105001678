import type { IconTypes } from "solid-icons";
import { Show, splitProps } from "solid-js";
import { StIcon } from "~/components/icons";
import { classNames } from "~/lib/classNames";
import styles from "./SecondaryTransitionCTA.module.css";
import type { CTAProps } from "./types";

interface ButtonProps extends CTAProps {
  label: string;
  icon?: IconTypes;
  size?: "small" | "medium";
  inactive?: boolean;
  transition: boolean;
  transitionIcon?: IconTypes;
  transitionText?: string;
  rounded?: boolean;
  accessiblePrefix?: string;
  accessibleSuffix?: string;
}
type AccessibleProps = RequireAtLeastOne<ButtonProps, "accessiblePrefix" | "accessibleSuffix">;
type TransitionProps = RequireAtLeastOne<AccessibleProps, "transitionIcon" | "transitionText">;

export const SecondaryTransitionCTA = (props: TransitionProps) => {
  const [local, rest] = splitProps(props, [
    "label",
    "accessiblePrefix",
    "accessibleSuffix",
    "icon",
    "class",
    "rounded",
    "inactive",
    "transition",
    "transitionIcon",
    "transitionText",
    "size",
  ]);

  return (
    <button
      {...rest}
      type={props.type ?? "button"}
      class={classNames(
        styles["secondary-transition-cta"],
        local.size === "small" && styles["secondary-transition-cta--small"],
        local.transition && styles["secondary-transition-cta--transition"],
        local.transitionIcon && !local.transitionText && styles["secondary-transition-cta--icon-only"],
        local.rounded && styles["secondary-transition-cta--rounded"],
        local.inactive && styles["secondary-transition-cta--inactive"],
        local.class,
      )}
      tabIndex={0}
      disabled={local.inactive || local.transition}
    >
      <span class={styles["secondary-transition-cta__default"]} aria-hidden={local.transition}>
        <Show when={local.icon}>
          {(icon) => <StIcon icon={icon()} class={styles["secondary-transition-cta__icon"]} size="1.25rem" />}
        </Show>
        <Show when={local.accessiblePrefix}>
          <span class="screen-reader">{local.accessiblePrefix}</span>
        </Show>
        <span class={styles["secondary-transition-cta__label"]}>{local.label}</span>
        <Show when={local.accessibleSuffix}>
          <span class="screen-reader">{local.accessibleSuffix}</span>
        </Show>
      </span>
      <span class={styles["secondary-transition-cta__transition"]} aria-hidden={!local.transition}>
        <Show when={local.transitionIcon}>
          {(icon) => <StIcon icon={icon()} class={styles["secondary-transition-cta__icon"]} size="1.25rem" />}
        </Show>
        <Show when={local.accessibleSuffix}>
          <span class="screen-reader">Successfully</span>
        </Show>
        <Show when={local.transitionText}>
          <span class={styles["secondary-transition-cta__label"]}>{local.transitionText}</span>
        </Show>
      </span>
    </button>
  );
};
