import { stAnalytics } from "@repo/analytics";
import type { assets as assetsNS } from "@repo/client";
import { FaSolidFolderTree } from "solid-icons/fa";
import { RiSystemUploadCloud2Line } from "solid-icons/ri";
import { TbFolderFilled } from "solid-icons/tb";
import {
  type Accessor,
  type Resource,
  createEffect,
  createSignal,
  onCleanup,
  onMount,
  Show,
  startTransition,
} from "solid-js";
import { CollectionAssetsTable } from "~/components/CollectionAssetTable";
import { Anchor } from "~/components/cta/Anchor";
import { PrimaryCTA } from "~/components/cta/PrimaryCTA";
import { SecondaryCTA } from "~/components/cta/SecondaryCTA";
import { HorizontalRule } from "~/components/HorizontalRule";
import { InfoPanel } from "~/components/InfoPanel";
import { SectionHeader } from "~/components/SectionHeader";
import { usePromptContext } from "~/domains/chat/prompt/PromptContext";
import { pluralize } from "~/lib/string/pluralize";
import { formatCollectionName } from "~/lib/ui/formatCollectionName";
import { getScreenType } from "~/lib/ui/getScreenType";
import { ProcessingReadableState, readableProcessingState } from "~/lib/ui/readableProcessingState";
import { useWire } from "~/wire";
import styles from "./AssetsDrawerScreen.module.css";

interface Props {
  shown: Accessor<boolean>;
  refetch: () => void;
  assets: Resource<{ count: number; assets: Record<string, assetsNS.AssetSnapshot[]> }>;
}

export const AssetsDrawerScreen = (props: Props) => {
  const wire = useWire();
  const { activeCollection, setShowUploadModal } = usePromptContext();
  const collections = wire.services.collections;
  const col = () => activeCollection();
  const active = () => collections.getCollection(col()?.id || "");
  const [subAssets, setSubAssets] = createSignal<assetsNS.AssetSnapshot[]>([]);

  let canPoll = false;
  let timeout: ReturnType<typeof setTimeout> | undefined;
  const poll = () => {
    const assets = props.assets()?.assets;
    if (assets) {
      const result = Object.keys(assets).filter((entry) => {
        if (!assets[entry]) return false;
        return (
          assets[entry].filter(
            (asset) =>
              readableProcessingState(asset.lifecycleState, asset.modifiedAt) !== ProcessingReadableState.Ready &&
              readableProcessingState(asset.lifecycleState, asset.modifiedAt) !== ProcessingReadableState.Failed,
          ).length > 0
        );
      });
      if (result.length > 0) {
        startTransition(() => {
          props.refetch();
        });
        if (canPoll) {
          timeout = globalThis.setTimeout(poll, 3000);
        }
      } else {
        timeout = undefined;
      }
    }
  };

  createEffect((prev) => {
    if (prev !== props.shown() && !timeout && props.shown()) {
      poll();
    }
    if (!props.shown() && timeout) {
      clearTimeout(timeout);
    }
  });

  createEffect(() => {
    if (props.shown()) {
      const assets = props.assets()?.assets;
      let result: assetsNS.AssetSnapshot[] = [];
      if (assets && col()?.id) {
        Object.keys(assets)
          .filter((id) => id !== col()?.id)
          .forEach((entry) => {
            if (assets[entry]) {
              result = [...result, ...assets[entry]];
            }
          });
        setSubAssets(result);
      }
    }
  });

  onMount(() => {
    canPoll = true;
    onCleanup(() => {
      if (timeout) {
        clearTimeout(timeout);
      }
      canPoll = false;
    });
  });

  return (
    <div class={styles["assets-drawer"]}>
      <p class={styles["assets-drawer__title"]}>Assets you're chatting with:</p>
      <Show when={active()}>
        <div class={styles["assets-drawer__section"]}>
          <Show
            when={(props.assets()?.assets[active()!.id] ?? []).length > 0}
            fallback={
              <InfoPanel
                icon={TbFolderFilled}
                title={`0 assets from your ${formatCollectionName(active()!.label)}.`}
                messages={["Assets from sub-Collections are still available."]}
                variant="info"
              >
                <PrimaryCTA
                  class={styles["assets-drawer__cta"]}
                  data-test-id="right-drawer-upload"
                  icon={RiSystemUploadCloud2Line}
                  label={`Add assets to your ${formatCollectionName(active()!.label)}.`}
                  accessibleSuffix="to your current Collection."
                  onClick={() => {
                    stAnalytics.track("click_tracking", {
                      cta: "upload",
                      position: "right_drawer",
                      screen: getScreenType(),
                    });
                    setShowUploadModal(true);
                  }}
                />
              </InfoPanel>
            }
          >
            <SectionHeader
              modifier="thin-bottom-padding"
              title={`${props.assets()?.count} ${pluralize(props.assets()?.count, "asset")} from your ${formatCollectionName(active()!.label)}.`}
              icon={TbFolderFilled}
            />
            <CollectionAssetsTable
              modifier="drawer"
              collectionName={active()!.label}
              data={props.assets()?.assets[active()!.id]!}
            />
            <SecondaryCTA
              class={styles["assets-drawer__cta"]}
              data-test-id="right-drawer-upload"
              icon={RiSystemUploadCloud2Line}
              label={`Add assets to your ${formatCollectionName(active()!.label)}.`}
              accessibleSuffix="to your current Collection."
              onClick={() => {
                stAnalytics.track("click_tracking", {
                  cta: "upload",
                  position: "right_drawer",
                  screen: getScreenType(),
                });
                setShowUploadModal(true);
              }}
            />
          </Show>
        </div>
        <Show when={subAssets().length > 0}>
          <HorizontalRule />
          <div class={styles["assets-drawer__section"]}>
            <SectionHeader
              modifier="thin-bottom-padding"
              title={`${subAssets().length} additional ${pluralize(subAssets().length, "asset")} from sub-Collections.`}
              icon={FaSolidFolderTree}
            />
            <CollectionAssetsTable modifier="drawer" collectionName="Other child Collections" data={subAssets()} />
          </div>
        </Show>
      </Show>
      <p class={styles["assets-drawer__footer"]}>
        <Anchor
          data-test-id="storytile-docs-link"
          accessibleSuffix="Visit our documentation to"
          label="Learn more about assets and Collections."
          href="https://docs.storytell.ai/features/collections"
          target="_blank"
        />
      </p>
    </div>
  );
};
